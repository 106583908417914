import React, { useState, useEffect } from 'react';
import { Button, Stack, Modal, HStack, Avatar, SkeletonLoader, Text, Icon, Loading } from '@arcault-ui/shared-components';
import RFPSummary from './RFPSummary';
import ProposalForm from './ProposalForm';
import ProposalSummary from './ProposalSummary';
import { leadsAPI } from '../../actions/leads';
import { proposalsAPI } from '../../actions/proposals';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Tab, TabList, TabPanel } from '../Tabs';
import { companyProfileAPI } from '../../actions/profile';
import { useSettings } from '../../context/SettingsContext';

import CompanyProfile from '../profile/CompanyProfile';

const LetterHead = ({ profile }) => (
  <HStack variant="spread" className="proposal-header">
    <HStack variant="start" className="proposal-header-left">
      <Avatar user={{ avatarUrl: profile?.avatarUrl }} />
      <Text variant="h2">{profile?.name}</Text>
    </HStack>
    <Stack variant="center" className="proposal-header-right">
      <Text variant="body-text-large">{profile?.address}</Text>
      <Text variant="body-text-large">{profile?.url}</Text>
    </Stack>
  </HStack>
)

const hasRead = (lead, userId) => (lead?.readBy || []).includes(userId)

const IndividualLead = ({ activeRFPId, loadItems }) => {
    const [lead, setLead] = useState(false);
    const [profile, setProfile] = useState({});
    const [proposal, setProposal] = useState({});
    const [isSent, setIsSent] = useState(false);
    const [activeTab, setActiveTab] = useState('Customer Info');
    const [profileModal, setProfileModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingProposal, setIsLoadingProposal] = useState(false);

    const navigate = useNavigate(); // Initialize useNavigate
    const { userInfo } = useSettings();

    const infoLoad = async () => {
      setIsLoading(true);
      const data = await companyProfileAPI.get()
      setProfile(data?.data);

      await leadsAPI.markRead(activeRFPId, true)
      if (activeRFPId) {
        await loadLead(activeRFPId);
      }
      setIsLoading(false);

    }


    useEffect(() => {
      infoLoad();
    }, [activeRFPId])

    const loadAll = async () => {
      await leadsAPI.markRead(activeRFPId, true)
      await loadItems();
    }


    useEffect(() => {
      if (lead && userInfo) {
        if (!hasRead(lead, userInfo?.userId)) {
          setTimeout(() => {
            loadAll();
          }, 3000);
        }
      }
    }, [lead, userInfo])
  
    const getProposals = async () => {
      setIsLoadingProposal(true);
      const data = await proposalsAPI.draft(lead.id)  
      const { id } = data.data;
        
      const savedData = await proposalsAPI.get(id)
      if (savedData.data && savedData.data[0]) {
        console.log('data', savedData)
        if (savedData.data[0].status === 'SENT') {
          setIsSent(true);
        }
        setProposal(savedData.data[0]);
      } else {
        setProposal(data.data);
      }
      setIsLoadingProposal(false);

    }
    
    const loadLead = async (id) => {
        try {
            const data = await leadsAPI.view(id);
            setLead(data.data);
        } catch (error) {
            console.error("Error loading files:", error);
        }
        
    }

    useEffect(() => {
        if (lead?.id) {
            getProposals();
        }
    }, [lead])

    return (
        <Stack className="leads-dashboard" variant="start">
            <Text variant="h2">
                <HStack variant="start">
                  <div onClick={() => navigate('/leads')}>
                    <Icon name="arrow_back_ios" />
                  </div>
                  {lead.title}
                </HStack>
            </Text>
            
            <Stack className="full-height-card leads-dashboard-container">
              <TabList>
                <Tab activeTab={activeTab} label="Customer Info" onClick={setActiveTab}>
                  Customer Info
                </Tab>
                <Tab activeTab={activeTab} label="Proposal" onClick={setActiveTab}>
                  Proposal
                </Tab>
              </TabList>
        
              <TabPanel activeTab={activeTab} label="Customer Info">
                <RFPSummary rfp={lead} isLoading={isLoading} />
              </TabPanel>
                  <TabPanel activeTab={activeTab} label="Proposal">
                    <>
                      {isLoadingProposal && <Loading label="Loading Proposal" />}
                      <div className="your-proposal neu-card-min">
                        <LetterHead profile={profile} />
                        <div className="instructions"><Text variant="body">
                          This proposal will be sent along with your company profile data. 
                          </Text>
                          <Button type="link" onClick={() => setProfileModal(true)}>Edit Profile.</Button>
                          </div>
                        <Modal className="profile-modal" size="lg" isOpen={profileModal} onClose={() => setProfileModal(false)} title="Edit Profile">
                          <CompanyProfile />
                        </Modal>
                        {(!isSent) && <ProposalForm isLoading={isLoadingProposal} proposal={proposal} setIsSent={setIsSent} setProposal={setProposal} lead={lead} />}
                        {isSent && <ProposalSummary isLoading={isLoadingProposal}  proposal={proposal} />}
                      </div>
                    </>
                </TabPanel>
            </Stack>
        </Stack>
    );
};

export default IndividualLead;