import { Avatar, Card, HStack, Stack, Text, Icon } from '@arcault-ui/shared-components';

import '../leads/Leads.css';

const CustomerCardView = ({ callContact, onChat, onEmail, data, onClick, editCustomer, deleteCustomer }) => {
    return (
        <Card 
            onClick={() => onClick(data.contactId)}
            data-testId="customer-card"
            className={`neu-card-low customer-card ${data.status === 'OPEN' ? 'dot' : ''}`}
            footer={(
                <HStack variant="spread" className="customer-card-footer">
                    <HStack className="icon-row" variant="start">
                        {data.phone && <Icon name="call" onClick={() => callContact(data)} />}
                        {data.email && <Icon name="drafts" onClick={() => onEmail(data.contactId)} />}
                        <Icon name="chat" onClick={() => onChat(data.contactId)} />
                    </HStack>
                    <HStack  className="icon-row" variant="end">
                        <Icon onClick={() => editCustomer(data)} name="edit" /> <Icon onClick={() => deleteCustomer(data.contactId)} name="delete" />
                    </HStack>
                </HStack>
            )}>
            <Stack className="customer-card-inner arcault-stack-low-gap" variant="start">
                <HStack className="customer-card-name-block"> 
                    <Avatar user={data} />
                    <div className="customer-card-title">
                    <Text variant="h3">{data.firstName}</Text>
                    <Text variant="h2">{data.lastName}</Text>
                    </div>
                </HStack>
                <Text variant="caption">Created: {new Date(data.updatedAt).toLocaleString()}</Text>
            </Stack>
        </Card>
    );
};

export default CustomerCardView;