import React, { useState } from 'react';
import { Tab, TabList, TabPanel, Text, FormInput, Form, Button } from '@arcault-ui/shared-components'
import { Box, TextField, Typography } from '@mui/material';
import { TabContext } from '@mui/lab';
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';

const ContactToolbox = ({ contact , onActivity}) => {
  const axiosWithCredentials = useAxiosWithCredentials();
  const [value, setValue] = useState('2');
  const [activeTab, setActiveTab] = useState('Email');
  const [formData, setFormData] = useState({})
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const createActivity = async (activity) => {
    try {
      // Replace this URL with your actual endpoint
      const response = await axiosWithCredentials.post(`/crm/activity/ua/${contact.contactId}`,
      activity
      );
      onActivity();
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
  };

  const handleAction = (actionType) => {
    // Placeholder for action logic, e.g., API call to send email, save note, or schedule a call
    switch (actionType) {
    case 'note':
      createActivity({type: "note", details: formData.note});
      setFormData({})
      return;
    default:
      console.log(`${actionType} for`, contact);
      return;
  }
  };

  return (
    <div>
      <TabContext value={value}>
        <TabList>
          <Tab activeTab={activeTab} onClick={setActiveTab} label="Note">Note</Tab>
          <Tab activeTab={activeTab} onClick={setActiveTab} label="Schedule">Schedule</Tab>
          <Tab activeTab={activeTab} onClick={setActiveTab} label="Email">Email</Tab>
          <Tab activeTab={activeTab} onClick={setActiveTab} label="Voice Settings" >Voice Setting</Tab>
        </TabList>
        <TabPanel activeTab={activeTab}  label="Schedule">
          <Text variant="h3">Schedule a Follow-up Call</Text>
          <Form 
            fields={[
              { 
                id: 'datetime',         
                label: "Date and Time",
                type: "datetime-local",
                fullWidth: true,
              }
            ]}
            updateFunction={() => handleAction('Schedule Call')}
            saveText="Schedule"
          />
        </TabPanel>
        <TabPanel activeTab={activeTab}  label="Note">
          <Text variant="h3">Create a Note</Text>
          <Form 
            fields={[
              { 
                id: 'note',         
                label: "Note",
                type: "textarea",
                fullWidth: true,
              }
            ]}
            saveText="Schedule"
            updateFunction={() => handleAction('note')}
          />
        </TabPanel>
        <TabPanel activeTab={activeTab}  label="Email">
          <Text variant="h3">Send an Email</Text>
          <Form 
            fields={[
              { 
                id: 'subject',         
                label: "Subject",
                type: "text",
                fullWidth: true,
              },
              { 
                id: 'message',         
                label: "Message",
                type: "textarea",
                fullWidth: true,
              }
            ]}
            saveText="Send Email"
            updateFunction={() => handleAction('Send Email')}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default ContactToolbox;
