import setupAxiosWithCredentials from "../utils/setupAxiosWithCredentials";
import { axiosWithCredentials } from "../api/axios";
import Base from './base';

const BASE_URL = 'crm/contact';

class Customers extends Base {
    async delete(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.delete(this.base_url + '/meta/' + id);
            return data;
        } finally {
            removeInterceptors();
        }
    }
}

export const customersAPI = new Customers(BASE_URL);
