import './App.css';

import CallCenter from './components/contactCenter/CallCenter'

import CRMLayout from './components/CRMLayout'
import Customers from './components/customers/Customers'
import Leads from './components/leads/Leads'
import Proposals from './components/Proposals'
import Proposal from './components/Proposal'
import Profile from './components/profile/Profile'
import CompanyProfile from './components/profile/CompanyProfile'
import IndividualLead from './components/leads/IndividualLead'

import Terms from './components/auth/Terms'
import Privacy from './components/auth/Privacy'
import OAuthCallback from './components/auth/OAuthCallback';
import Logout from './components/auth/Logout'; 
import SignIn from './components/auth/SignIn'; 
import SignUp from './components/auth/SignUp'; 
import RequireAuth from './components/auth/RequireAuth';
import ForgotPassword from './components/auth/ForgotPassword'

import ConfirmEmail from './components/ConfirmEmail'
import { MessageProvider } from './context/MessageContext'; 
import { SettingsProvider } from './context/SettingsContext';  // Only wrap AppRoutes now

import Creds from './components/Creds';

import NotFound from './components/NotFound'
import { Route, Routes } from 'react-router-dom';
import { CallProvider } from './context/CallContext'; // Import CallProvider

function App() {

  return (

    <MessageProvider>
      <SettingsProvider>
        <CallProvider> {/* Wrap Routes with CallProvider */}
          <Routes>
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/oauth2/idpresponse" element={<OAuthCallback />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/confirmemail" element={<ConfirmEmail />} />
            {/* authenticated routes */}
            <Route element={<RequireAuth />}>
              <Route element={<CRMLayout />}>
                <Route path="/" element={<Leads />} />
                <Route path="/creds" element={<Creds />} />
                <Route path="/connect" element={<CallCenter />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/company" element={<CompanyProfile />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/customers/:id" element={<Customers />} />
                <Route path="/contacts" element={<Customers />} />
                <Route path="/contacts/:id" element={<Customers />} />
                <Route path="/leads" element={<Leads />} />
                <Route path="/leads/:id" element={<Leads />} />
                <Route path="/proposals" element={<Proposals />} />
                <Route path="/proposals/:proposalsId" element={<Proposal />} />
              </Route>
            </Route>
            {/* catch all */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </CallProvider>
      </SettingsProvider>
    </MessageProvider>
  );
}

export default App;