import React from 'react';
import { HStack, Stack, Data, SkeletonLoader, Text } from '@arcault-ui/shared-components';

const RFPSummary = ({ rfp, isLoading }) => {
  return (
    <Stack className="rfp-summary" variant="start">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {(true || isLoading) && <SkeletonLoader isText={true} count={3}  />}
      </div>
      <HStack className="flex-no-grow data-container">
          {isLoading && <div style={{ width: '50%' }}>
            <SkeletonLoader isText={true} count={5}  />
          </div>}
          {isLoading && <div style={{ width: '50%' }}>
            <SkeletonLoader isText={true} count={5}  />
          </div>}
          <Data data={(rfp?.profileData || []).slice(0, (rfp?.profileData || []).length / 2)} />
          <Data data={(rfp?.profileData || []).slice((rfp?.profileData || []).length / 2, (rfp?.profileData || []).length)} />
      </HStack>
      <Text variant="h3">Summary</Text>
      <Text variant="body">
        {rfp.summary}
        {isLoading && <SkeletonLoader isText={true} count={3}  />}
      </Text>
      <Text variant="h3">Notes</Text>
      <Text variant="body">
        {rfp.notes}
        {isLoading && <SkeletonLoader isText={true} count={3}  />}
      </Text>
    </Stack>
  );
};

export default RFPSummary;
