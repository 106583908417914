
import React from 'react';
import { NavLink } from 'react-router-dom';
import { HStack, Icon, Stack, Text } from '@arcault-ui/shared-components';

const menuItems = [
  { url: "/connect", icon: "phone", label: "Connect" },
  { url: "/contacts", icon: "groups", label: "Contacts" },
  { url: "/leads", icon: "assignment", label: "Leads" },
  // { url: "/proposals", icon: "handshake", label: "Proposals" },
];

const LeftNavMenu = ({ className, onClick }) => {
  return (
    <Stack
      className={`navigation ${className}`}
    >
      <Stack spacing="2" align="stretch">
        {menuItems.map(({ url, icon, label }) => (
          <NavItem icon={icon} label={label} href={url} onClick={onClick} />
        ))}
      </Stack>
    </Stack>
  );
};

const NavItem = ({ icon, label, href, onClick }) => (
  <NavLink
    to={href}
    onClick={onClick}
    className="nav-item"
  >
    <HStack
      className="nav-item-inner"
    >
      <Icon name={icon} />
      <Text>{label}</Text>
    </HStack>
  </NavLink>
);

export default LeftNavMenu;