import React, { useState } from 'react';
import { Button, Icon, HStack } from '@arcault-ui/shared-components';

const PhoneControls = ({ status, incomingCall, makeCall, acceptCall, declineCall, hangupCall }) => {
  

  return (
    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      {incomingCall ? (
        <HStack>
          <Button type="primary" onClick={acceptCall} >
            Accept
          </Button>
          <Button type="outline" onClick={declineCall}>
            Decline
          </Button>
        </HStack>
      ) : (
        <HStack className="call-buttons" variant="end">
          {status ===  'In Progress' && (
            <Button type="outline" onClick={hangupCall}>
              Hang Up
            </Button>
          )}
          {status !==  'In Progress' && (
            <Button type="primary" onClick={makeCall} >
              Call <Icon name="phone" />
            </Button>
          )}
        </HStack>
      )}
    </div>
  );
};

export default PhoneControls;
