import React, { useState } from 'react';
import { Card, HStack, Stack, Text, Icon } from '@arcault-ui/shared-components';
import './Leads.css';
import { statusDisplay } from './constants';
import { leadsAPI } from '../../actions/leads';
import { useSettings } from '../../context/SettingsContext';

const SUMMARY_WORD_LIMIT = 30;

const hasRead = (lead, userId) => (lead?.readBy || []).includes(userId);
  
const LeadsCardView = ({ data, onClick }) => {

    const [modifiedData, setModifiedData] = useState(data);

    const { userInfo } = useSettings();

    const truncateSummary = (summary) => {
        const words = summary.split(' ');
        return words.length >= SUMMARY_WORD_LIMIT ? words.slice(0, SUMMARY_WORD_LIMIT).join(' ') + '...' : summary;
    };

    const onArchive = async () => {
        const response = await leadsAPI.archive(data.id, !data.archive);
        setModifiedData({ ...response.data });
    }

    const markUnread = async () => {
        const response = await leadsAPI.markRead(data.id, false);
        setModifiedData({ ...response.data });
    }

    return (
        <>
            {modifiedData.status !== 'ARCHIVED' && <Card 
                onClick={() => onClick(data.id)}
                className={`neu-card-low lead-card ${!hasRead(modifiedData, userInfo?.userId) ? 'dot' : ''}`}
                footer={(
                    <HStack variant="end" className="icon-row">
                        {hasRead(modifiedData, userInfo?.userId) && <Icon name="mark_as_unread" onClick={() => markUnread()} />}
                        <Icon name={modifiedData.archive ? 'unarchive' : 'archive'} onClick={() => onArchive()} />
                    </HStack>
                )}>
                <Stack className="lead-card-inner" variant="start">
                    <div>{statusDisplay(modifiedData.status)}</div>
                    <div className="lead-card-title">
                        <Text variant="subheading">{modifiedData.title}</Text>
                    </div>
                    <Text variant="caption">Location: {modifiedData.geography}</Text>
                    <Text variant="body-large">{truncateSummary(modifiedData.summary)}</Text>
                </Stack>
            </Card>}
        </>
    );
};

export default LeadsCardView;