import React from 'react';
import { Card, ChatPanel, EmptyState, Form, Stack, HStack, Text, Icon, Vault, Tab, TabList, TabPanel } from '@arcault-ui/shared-components';
import useDocuments from '../../hooks/useDocuments';
import './Customers.css';

const IndividualCustomer = ({ activeTab, setActiveTab, activeCustomer, navigate, callContact }) => {
    const handleAction = (id) => {
        console.log('Action', id);
    }
  return (
    <Stack className="leads-dashboard" variant="start">
      <Text variant="h2">
        <HStack>
          <div onClick={() => navigate('/customers')}>
          <Icon name="arrow_back_ios"  />
        </div> {activeCustomer?.name}</HStack>
      </Text>
      <HStack className="full-height-card leads-dashboard-container">
        <div className="customer-info">
          {activeCustomer?.email && <div className="customer-info-item"><Icon name="email" />{activeCustomer?.email}</div>}
          {activeCustomer?.phone && <div onClick={() => callContact(activeCustomer)}className="customer-info-item"><Icon name="phone" />{activeCustomer?.phone}</div>}
          {activeCustomer?.address && <div className="customer-info-item"><Icon />{activeCustomer?.address}</div>}
        </div>
        <div className="customer-vault">
            <TabList>
                {activeCustomer?.userId && <Tab activeTab={activeTab} onClick={setActiveTab} label="Documents">Documents</Tab>}
                <Tab activeTab={activeTab} onClick={setActiveTab} label="Chat">Chat</Tab>
                <Tab activeTab={activeTab} onClick={setActiveTab} label="Personal Info">Personal Info</Tab>
                <Tab activeTab={activeTab} onClick={setActiveTab} label="Notes">Notes</Tab>
                <Tab activeTab={activeTab} onClick={setActiveTab} label="Schedule">Schedule Follow-up</Tab>
                <Tab activeTab={activeTab} onClick={setActiveTab} label="Email">Email</Tab>
            </TabList>
            <TabPanel activeTab={activeTab} label="Chat">
                <Card className="chat-panel-container card-no-shadow">
                    <ChatPanel />
                </Card>
            </TabPanel>
            <TabPanel activeTab={activeTab} label="Personal Info">
              <EmptyState icon={<Icon name="person" />} title="We have no personal info for this contact" subtitle="Please check back later" />
            </TabPanel>
            <TabPanel activeTab={activeTab} label="Documents">
              {activeCustomer?.userId && <Vault 
                  category={`crm_${activeCustomer?.userId}`}
                  useDocuments={useDocuments}
              />}
            </TabPanel>
            <TabPanel activeTab={activeTab} label="Schedule">
              <Text variant="h3">Schedule a Follow-up Call</Text>
              <div>
              <Form 
                fields={[
                  { 
                    id: 'datetime',         
                    label: "Date and Time",
                    type: "datetime-local",
                    fullWidth: true,
                  }
                ]}
                updateFunction={() => handleAction('Schedule Call')}
                saveText="Schedule"
              />
              </div>
            </TabPanel>
            <TabPanel activeTab={activeTab}  label="Notes">
              <Stack variant="start">
                <Text variant="h3">Notes</Text>
                <div>
                <Form 
                  fields={[
                    { 
                      id: 'note',         
                      label: "Add Note",
                      type: "textarea",
                      fullWidth: true,
                    }
                  ]}
                  saveText="Create Note"
                  updateFunction={() => handleAction('note')}
                />
                </div>
              </Stack>
              
            </TabPanel>
                  <TabPanel activeTab={activeTab}  label="Email">
                    <Text variant="h3">Send an Email</Text>
                    <div>
                      <Form 
                        fields={[
                          { 
                            id: 'subject',         
                            label: "Subject",
                            type: "text",
                            fullWidth: true,
                          },
                          { 
                            id: 'message',         
                            label: "Message",
                            type: "textarea",
                            fullWidth: true,
                          }
                        ]}
                        saveText="Send Email"
                        updateFunction={() => handleAction('Send Email')}
                      />
                    </div>
                  </TabPanel>
        </div>
      </HStack>
    </Stack>
  );
};

export default IndividualCustomer;
