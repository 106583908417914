// import { Box, Button, Grid, Typography } from '@mui/material';
import { HStack, Stack, Button, Card, Text } from '@arcault-ui/shared-components';

const Dialer = ({ handleDialButton}) => {

// Dial pad layout
  const dialPad = [
    {id:1, value: '1',},
    {id:2, value: '2', sub: "ABC"},
    {id:3, value: '3', sub: "DEF"},
    {id:4, value: '4', sub: "GHI"},
    {id:5, value: '5', sub: "JKL"},
    {id:6, value: '6', sub: "MNO"},
    {id:7, value: '7', sub: "PQRS"},
    {id:8, value: '8', sub: "TUV"},
    {id:9, value: '9', sub: "WXYZ"},
    {id:10, value: '*',},
    {id:0, value: '0',},
    {id:11, value: '#',},
  ];

  return (
        <Stack container spacing={1}>
          <HStack>
            {dialPad.slice(0, 3).map((key) => (
                  <Card className="neu-card-min min-card" onClick={() => handleDialButton(key)}>
                    <Stack className="dial-num">
                      <Text variant="h5" display="block" gutterBottom>
                        {key.value}
                      </Text>
                      <Text variant="caption" display="block" gutterBottom>
                        {key.sub || "\u00A0"}
                      </Text>
                    </Stack>
                </Card>
            ))}
          </HStack>
          <HStack>
            {dialPad.slice(3, 6).map((key) => (
                  <Card className="neu-card-min min-card" onClick={() => handleDialButton(key)}>
                    <Stack className="dial-num">
                      <Text variant="h5" display="block" gutterBottom>
                        {key.value}
                      </Text>
                      <Text variant="caption" display="block" gutterBottom>
                        {key.sub || "\u00A0"}
                      </Text>
                    </Stack>
                </Card>
            ))}
          </HStack>
          <HStack>
            {dialPad.slice(6, 9).map((key) => (
                  <Card className="neu-card-min min-card" onClick={() => handleDialButton(key)}>
                    <Stack className="dial-num">
                      <Text variant="h5" display="block" gutterBottom>
                        {key.value}
                      </Text>
                      <Text variant="caption" display="block" gutterBottom>
                        {key.sub || "\u00A0"}
                      </Text>
                    </Stack>
                </Card>
            ))}
          </HStack>
          <HStack>          
            {dialPad.slice(9, 12).map((key) => (
                  <Card className="neu-card-min min-card" onClick={() => handleDialButton(key)}>
                    <Stack className="dial-num">
                      <Text variant="h5" display="block" gutterBottom>
                        {key.value}
                      </Text>
                      <Text variant="caption" display="block" gutterBottom>
                        {key.sub || "\u00A0"}
                      </Text>
                    </Stack>
                </Card>
            ))}
          </HStack>
        </Stack>
  );
};

export default Dialer;
