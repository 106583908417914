import React, { useState, useEffect } from 'react';
import { Card, Tag, Text, Table, HStack, SkeletonLoader, Stack } from '@arcault-ui/shared-components';
import IndividualLead from './IndividualLead';
import { humanReadableStatus, RFP_NON_RESPONSE, RFP_RESPONSE, RFP_RESPONSE_ACCEPTED, RFP_RESPONSE_REJECTED, statusDisplay } from "./constants";
import CardView from './LeadsCardView';
import { leadsAPI } from '../../actions/leads';
import { proposalsAPI } from '../../actions/proposals';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useParams } from 'react-router-dom';
import { useSettings } from '../../context/SettingsContext';


const Leads = () => {
  const [displayedLeads, setDisplayedLeads] = useState([]);
  const [stats, setStats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStats, setIsLoadingStats] = useState(false);

  const { userInfo } = useSettings();

  const navigate = useNavigate(); // Initialize useNavigate
  const { id } = useParams();

  const fetchLeads = async () => {
    try {
      const data = await leadsAPI.get();
      return data.data
    } catch (error) {
      console.error("Error loading files:", error);
    }
  };

  const fetchProposals = async () => {
    try {
      const data = await proposalsAPI.get();
      return data.data;
    } catch (error) {
      console.error("Error loading files:", error);
    }
  };

  const loadAll = async () => {
    setIsLoadingStats(true);
    setIsLoading(true);
    const leads = await fetchLeads();
    const preppedLeads = [...(leads || []).filter(lead => lead.title).map(lead => ({...lead, ...lead.rfp, service: 'rfp'}))]
    const proposals = await fetchProposals();
    const leadsWProposals = preppedLeads.map(lead => {
      const proposal = proposals.find(proposal => proposal.rfpId === lead.rfpId);
      return {
        ...lead,
        status: proposal ? proposal?.status : lead.status,
      };
    })

    const statusCounts = leadsWProposals.reduce((acc, lead) => {
      acc[lead.status] = (acc[lead.status] || 0) + 1;
      return acc;
    }, {});
 
    const statuses = [RFP_NON_RESPONSE, RFP_RESPONSE, RFP_RESPONSE_ACCEPTED, RFP_RESPONSE_REJECTED];
    const calcPercentage = (num, dom) => dom = 0 ? 0 : Math.floor((100 * num) / dom);

    setIsLoadingStats(false);
    setStats(statuses.map((status, index) => {
      let percent = false;
      if (status === RFP_RESPONSE_ACCEPTED || status === RFP_RESPONSE_REJECTED) {
        percent = calcPercentage(statusCounts[status], statusCounts[RFP_RESPONSE]) || 0;
      } else if (status === RFP_RESPONSE) {
        percent = calcPercentage(statusCounts[RFP_RESPONSE], statusCounts[RFP_RESPONSE] + statusCounts[RFP_NON_RESPONSE]);
      }
      return ({
        id: index,
        label: humanReadableStatus(status),
        value: statusCounts[status],
        percent, 
      })
    }));

    setDisplayedLeads(leadsWProposals);
    setIsLoading(false);

  }

  useEffect(() => {
    loadAll();
  }, []);

  const StatCard = ({ id, label, value, percent }) => (
    <Card key={id} className="card-no-shadow stat-card">
    <HStack variant="center"><Text variant="body">{label}</Text></HStack>
    <HStack variant="center" className="stat-value">
      <Text variant="h1">{value || 0}</Text>
      {percent ? (
        <div className="stat-percent">
          <Text variant="h3">{percent || 0}%</Text>
        </div>
      ) : ''}
    </HStack>
  </Card>
  )

  return (
    <Card className="card-no-shadow leads-dashboard dashboard">
      <Text variant="h1">Leads</Text>
      {!id && (<HStack className="stat-stack">
        <Stack className="arcault-stack-low-gap">
          <Text variant="caption"> Current</Text>
          {isLoadingStats && <SkeletonLoader count={4} width="182px" height="112px" />}
          <HStack>
            {!isLoadingStats && stats.map(stat => (
              <StatCard {...stat} />
            ))}
          </HStack>
        </Stack>
        <div className="vertical-divider" />
        <Stack className="arcault-stack-low-gap">
          <Text variant="caption"> Total</Text>
          {isLoadingStats && <SkeletonLoader count={2} width="182px" height="112px" />}
          <HStack>
            {stats.slice(1, 3).map(stat => (
              <StatCard {...stat} />
            ))}
          </HStack>
        </Stack>
      </HStack>)}
      {!id && (
        <Table 
          isLoading={isLoading}
          filters={[
            {
              id: 'status',
              placeholder: 'Status',
              optionsMap: {
                'All': '',
                'OPEN': 'Active',
                'SENT': 'Proposal Sent',
              }
            }, {
              id: 'readBy',
              placeholder: 'Only Unread',
              type: 'toggle',
              filterLogic: (thisValue, value) => {
                console.log('thisValue', thisValue, value, userInfo?.userId)
                return !(thisValue || []).includes(userInfo?.userId);
              }
            }, /*{
              id: 'archive',
              placeholder: 'Show archived',
              type: 'toggle',
              filterLogic: (thisValue, value) => {
                if (value) return true
                return thisValue === false;
              }
            }*/
          ]}
          defaultViewType="card" CardView={CardView} rows={displayedLeads} columns={[
            { id: 'title', label: 'Title' },
            { id: 'geography', label: 'Location' },
            { id: 'timeline', label: 'Timeline' },
            { id: 'status', label: 'Status' },
            { id: 'created', label: 'Date Submitted' },
          ]} displayValue={
            (value, column) => {
              if (column.id === 'status') {
                console.log('value', value, column)
                return statusDisplay(value) || value;
              } else if (column.id === 'created') {
                const date = new Date(value);
                return date.toLocaleDateString();
              } else if (column.id === 'status') {
                return (<Tag variant="primary">{value}</Tag>)
              }
              return value;
            }
          }
          onClick={(id) => {
            navigate(`/leads/${id}`); // Navigate to the routed individual lead
          }}
        />
      )}
      {id && (
        <IndividualLead activeRFPId={id} loadItems={loadAll} />
      )}
    </Card>
    
  );
}


export default Leads;
