import React, { useState, useEffect} from 'react';

import { Card, Text, Stack, Tab, TabList, TabPanel, HStack,  Avatar } from '@arcault-ui/shared-components';
import { companyProfileAPI } from '../../actions/profile';
import './Profile.css'
import Form from '../common/Form';

const fields = [
  {
    id: 'name',
    label: 'Company Name'
  },
  {
    id: 'url',
    label: 'Website'
  },
  { id: 'address',
    label: 'Address',
    type: 'textarea',
  },
  {
    id: 'avatarUrl',
    label: 'Logo URL'
  }
]

const descriptionFields = [
  {
    id: 'description',
    label: 'Description',
    type: 'textarea',
    fullWidth: true,
  }, 
];

const Profile = () => {
  const [profile, setProfile] = useState({});
  const [activeTab, setActiveTab] = useState('Information');
  const [data, setData] = useState({});

  const loadData = async () => {
    const response = await companyProfileAPI.get() 
    setData(response.data);
  }

  useEffect(() => {
    loadData() 
  }, [])

  return (
    <HStack variant="center">
    <Card className="profile-card">
      <Text variant="h2">
        Company Profile
      </Text>
      <Stack>
        <HStack variant="center" className="profile-container">
          <Avatar
            alt="Profile Picture"
            user={{ 
              ...profile,
            }}
          />
        </HStack>

        <TabList>
          <Tab activeTab={activeTab} onClick={setActiveTab} label="Information">Information</Tab>
          <Tab activeTab={activeTab} onClick={setActiveTab} label="Description">Description</Tab>
        </TabList>
        <TabPanel activeTab={activeTab} label="Information">
          <Form fields={fields} initialState={{ data: profile }} updateFunction={companyProfileAPI.update} getFunction={profile?.id ? () => ({ data: profile }) : companyProfileAPI.get} dataCallback={setProfile} />
        </TabPanel>
        
        <TabPanel activeTab={activeTab} label="Description">
        <Form fields={descriptionFields} initialState={{ data: profile }} updateFunction={companyProfileAPI.update} getFunction={profile?.id ? () => ({ data: profile }) : companyProfileAPI.get} dataCallback={setProfile} />
        </TabPanel>
      </Stack>
    </Card>
    </HStack>
  );
};

export default Profile;

// initialState={{ data }} 