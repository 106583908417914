import React, { useState, useEffect } from 'react';
import { FormDialog, HStack, Card, Table, Text } from '@arcault-ui/shared-components';
import { FormControlLabel, Switch } from '@mui/material';
import { customersAPI } from '../../actions/customers';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import CardView from './CustomerCardView';
import { useParams } from 'react-router-dom';
import { useCallContext } from '../../context/CallContext';
import IndividualCustomer from './IndividualCustomer'; // Import the new component

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [initialValue, setInitialValue] = useState({});
  const [activeCustomer, setActiveCustomer] = useState(false);
  const [contactSyncEnabled, setContactSyncEnabled] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [activeTab, setActiveTab] = useState('Personal Info');
  const [isLoading, setIsLoading] = useState(false);

  const { callContact } = useCallContext();  
  const navigate = useNavigate();
  const { id } = useParams();

  const loadItems = async () => {
    setIsLoading(true);
    const data = await customersAPI.get();
    setIsLoading(false);

    setCustomers(data.data.map(c => ({
      ...c,
      name: c.firstName + ' ' + c.lastName,
    })));
  };

  const deleteCustomer = async (id) => {
    await customersAPI.delete(id);
    await loadItems();
  }

  const editCustomer = async (id) => {
      setInitialValue({ data: customers.find(c => c.id === id) })
      setShowAddCustomer(true)
  }

  const modifyCustomer = async (data) => {
    await customersAPI.edit(data.contactId, data);
    setShowAddCustomer(false);
    await loadItems();
  }


  useEffect(() => {
    loadItems();
  }, []);

  useEffect(() => {
    if (id && customers?.length > 0) setActiveCustomer(customers.find(c => c.contactId === id));
  }, [id, customers]);

  const addCustomer = async (data) => {
    await customersAPI.save(data);
    setShowAddCustomer(false);
    await loadItems();
  }

  const columns = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'tags', label: 'Tags', align: 'left' },
    { id: 'email', label: 'Email address', align: 'left' },
    { id: 'phone', label: 'Phone number', align: 'left' },
    { id: 'address', label: 'Location', align: 'left' },
  ];

  const actions = [
    {
      label: 'Edit',
      iconName: 'edit',
      onClick: (row) => alert(`Editing ${row.name}`),
    },
    {
      label: 'Delete',
      iconName: 'delete',
      onClick: (row) => alert(`Deleting ${row.name}`),
    },
  ];

  const handleToggleChange = (event) => {
    setContactSyncEnabled(event.target.checked);
  };


  return (
    <Card className="card-no-shadow leads-dashboard dashboard customers-dashboard">
      <HStack className="table-header" variant="spread">
        <Text variant="h1">Contacts</Text>
        
        {!id && (
          <FormDialog
            buttonText="Add Customer +"
            modalTitle={`${initialValue ? 'Edit' : 'Add'} Customer`}
            initialState={initialValue}
            updateFunction={initialValue?.id ? modifyCustomer : addCustomer}
            fields={[
              { id: 'firstName', label: 'First Name' },
              { id: 'lastName', label: 'Last Name' },
              { id: 'phone', label: 'Phone', type: 'phone' },
              { id: 'email', label: 'Email', type: 'email' },
              { id: 'address', label: 'Address', fullWidth: true },
              { id: 'tags', label: 'Tags', type: 'tags' },
              { id: 'notes', label: 'Notes', type: 'textarea', fullWidth: true },
            ]}
          />
        )}
      </HStack>
      {!id && (
        <Table
          isLoading={isLoading}
          defaultViewType="card"
          CardView={(props) => (
          <CardView {...props} 
            onChat={(contact) => {
              navigate(`/contacts/${contact}`);
              setActiveTab('Chat');
            }}
          onEmail={(contact) => {
            navigate(`/contacts/${contact}`);
            setActiveTab('Email');
          }} callContact={callContact} editCustomer={editCustomer} deleteCustomer={deleteCustomer} />)}
          columns={columns}
          rows={customers}
          actions={actions}
          service="customer"
          displayValue={(value, column) => {
            if (column.id === 'tags' && value) {
              return value.join(', ');
            }
            return value;
          }}
          onClick={
            (id) => navigate(`/customers/${id}`)// Navigate to the routed individual lead
            // (id) => setActiveCustomer(customers.find(c => c.id === id ))
          }
        />
      )}
      {id && (
        <IndividualCustomer setActiveTab={setActiveTab} activeTab={activeTab} callContact={callContact} activeCustomer={activeCustomer} navigate={navigate} />
      )}
    </Card>
  );
};

export default Customers;