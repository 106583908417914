import React, { useState } from 'react';

import { Button, FormInput, HStack, Stack, Loading } from '@arcault-ui/shared-components';
import { proposalsAPI } from '../../actions/proposals';
import { useNavigate } from 'react-router-dom';


const ProposalForm = ({ isLoading, setIsSent, proposal, setProposal }) => {
  const [isLoadingSend, setLoading] = useState(false);
   const navigate = useNavigate();

  const saveProposal = async () => {
    setLoading('Saving');
    await proposalsAPI.save(proposal);
    setLoading(false);
  }

  const sendProposal = async () => {
    setLoading('Sending');
    await saveProposal();
    await proposalsAPI.send(proposal.id);
    setIsSent(true);
    setLoading(false);
  }

  const handleOnChange = (id, e) => {
    setProposal({ ...proposal, [id]: e.target.value });
  }

  return (
    <Stack className="full-height-card">
      {isLoadingSend && <Loading label={isLoadingSend} />}
      <div className="proposal-textarea">
        <FormInput label="Summary" value={proposal?.summary} onChange={(e) => handleOnChange('summary', e)} type="textarea" className="flex-one"/>
      </div>
      <div className="proposal-textarea">
        <FormInput label="Scope of Work" 
          value={proposal?.scopeOfWork} 
          onChange={(e) => handleOnChange('scopeOfWork', e)} 
          type="textarea" className="flex-one"/>
      </div>
      <div className="proposal-textarea">
        <FormInput label="Pricing"
          value={proposal?.pricing}
          onChange={(e) => handleOnChange('pricing', e)} 
          type="textarea" className="flex-one"/>
      </div>
      
      <HStack>
        <FormInput
          value={proposal?.timing} 
          onChange={(e) => handleOnChange('timing', e)} 
          type="text" label="Est. Timing"/>
      </HStack>
      <HStack variant="end">
        <Button type="outline" onClick={saveProposal}>Save</Button>
        <Button type="primary" onClick={sendProposal}>Send</Button>
      </HStack>
    </Stack>
  )
}

export default ProposalForm;