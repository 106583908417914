import setupAxiosWithCredentials from "../utils/setupAxiosWithCredentials";
import { axiosWithCredentials } from "../api/axios";
import Base, { genericCall } from './base';

const BASE_URL = 'engage/leads';

class Leads extends Base {
    async view(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.get(BASE_URL + '/view/' + id);
            return data;
        } finally {
            removeInterceptors();
        }
    }

    async archive(id, archive) {
        const url_addition = archive ? 'archive' : 'unarchive';
        return await genericCall('put', '/engage/lead/a/' + url_addition + '/' + id);
    }

    // /engage/lead/a/unread/2bfbe07c-2e93-4006-9063-7f49d2b77a03
    async markRead(id, markRead) {
        const url_addition = markRead ? 'read' : 'unread';
        return await genericCall('put', '/engage/lead/a/' + url_addition + '/' + id);
    }
}

export const leadsAPI = new Leads(BASE_URL);
