import React, { createContext, useState, useEffect, useContext } from 'react';

const CallContext = createContext();

export const useCallContext = () => useContext(CallContext);

export const CallProvider = ({ children }) => {
  const [callDrawerOpen, setCallDrawerOpen] = useState(false);
  const [toContact, setToContact] = useState(null);

  useEffect(() => {
    if (!callDrawerOpen) setToContact(null);
  }, [callDrawerOpen])

  const callContact = (contact) => {
    setToContact(contact);
    setCallDrawerOpen(true);
  }

  return (
    <CallContext.Provider value={{ callDrawerOpen, setCallDrawerOpen, callContact, toContact, setToContact }}>
      {children}
    </CallContext.Provider>
  );
};
